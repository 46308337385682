import Web3 from "web3";

export default {
    state: {
        dialogIsActive: false,
        ownedVitaliks: [],
        selectedVitalik: null,
    },
    mutations: {
        SET_OWNED_VITALIKS: (state, ownedVitaliks) =>
            (state.ownedVitaliks = { ...ownedVitaliks }),
        ADD_OWNED_VITALIK: (state, ownedVitalik) => {
            const ownedVitaliks = Object.values(state.ownedVitaliks).map(
                (value) => {
                    return value;
                }
            );

            state.ownedVitaliks = [...ownedVitaliks, ownedVitalik];
        },
        SET_DIALOG: (state, payload) => (state.dialogIsActive = payload),
        SET_SELECTED_VITALIK: (state, payload) =>
            (state.selectedVitalik = payload),
    },
    actions: {
        getOwnedVitaliks: async ({ rootState, commit }) => {
            const { account } = rootState.web3;
            const { latestVitalik } = rootState.home;
            const { getVitalikInfo } = rootState.web3.smartContract.methods;
            const ownedVitaliks = [];

            commit("SET_IS_LOADING", true);

            for (let i = 1; i < latestVitalik.id; i++) {
                let {
                    0: owner,
                    1: currentlyForSale,
                    2: price,
                    3: timesSold,
                } = await getVitalikInfo(i).call();

                if (owner === account) {
                    ownedVitaliks.push({
                        id: Number(i),
                        owner,
                        currentlyForSale,
                        price: Web3.utils.fromWei(price),
                        timesSold,
                    });
                }
            }

            commit("SET_OWNED_VITALIKS", ownedVitaliks);
            commit("SET_IS_LOADING", false);
        },

        sellOwnedVitalik: async ({ rootState }, data) => {
            const { id, price } = data;
            const { account } = rootState.web3;
            const { sellVitalik } = rootState.web3.smartContract.methods;

            await sellVitalik(id, Web3.utils.toWei(price)).send({
                from: account,
                gas: 1000000,
            });
            window.location.reload();
        },

        giftOwnedVitalik: async ({ rootState }, data) => {
            const { id, receiver } = data;
            const { account } = rootState.web3;
            const { giftVitalik } = rootState.web3.smartContract.methods;

            await giftVitalik(id, receiver).send({
                from: account,
                gas: 1000000,
            });
            window.location.reload();
        },

        takeOwnedVitalikOffMarket: async ({ rootState }, selectedVitalik) => {
            const { account } = rootState.web3;
            const { dontSellVitalik } = rootState.web3.smartContract.methods;

            await dontSellVitalik(selectedVitalik).send({
                from: account,
                gas: 1000000,
            });
            window.location.reload();
        },
    },
    getters: {
        dialogIsActive: (state) => state.dialogIsActive,
        ownedVitaliks: (state) => state.ownedVitaliks,
        selectedVitalik: (state) => state.selectedVitalik,
    },
};
