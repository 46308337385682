<template>
    <v-dialog
        v-model="this.imageViewerVisibility"
        transition="dialog-bottom-transition"
        max-width="1800"
    >
        <v-card>
            <v-card-actions class="justify-end">
                <v-btn text @click="closeImageViewer()">X</v-btn>
            </v-card-actions>
            <v-row>
                <v-col id="image-viewer">
                    <v-img
                        v-if="this.vitalikToView"
                        contain
                        :src="`https://ipfs.io/ipfs/QmVfo5UEmYyfamtzU75dcDm6uexV9bjrMnvYXLenDopTSt/${this.vitalikToView}.png`"
                    >
                    </v-img>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ImageViewer",

    props: {
        imageUrl: String,
    },

    computed: {
        ...mapGetters(["imageViewerVisibility", "vitalikToView"]),
    },

    methods: {
        closeImageViewer() {
            this.$store.commit("SET_IMAGE_VIEWER_VISIBILITY", false);
        },
    },
};
</script>

<style scoped>
#image-viewer {
    padding: 25px;
}
</style>
