<template>
    <div>
        <v-row id="latest-vitalik-section" class="latest-vitalik-container">
            <v-col md="3">
                <h3 class="vitalik-sale-title">LATEST VITALIK FOR SALE</h3>
                <h4 class="vitalik-sale-subtitle">[NEVER BEFORE SOLD]</h4>
                <VitalikListing
                    class="latest-vitalik-listing"
                    v-if="this.latestVitalik"
                    :isVirgin="true"
                    :vitalikId="Number(this.latestVitalik.id)"
                />
            </v-col>
            <v-col md="6">
                <h3 class="vitalik-title">Welcome To Vitalik Rocks</h3>
                <p>
                    Vitalik.Rocks was originally born from a set of experiments
                    by a graphic designer to programmatically build
                    photo-realistic renders of mesh objects placed on a 2D photo
                    of an actual person. Inspired by a viral meme of tatted up
                    Vitalik (Lil Dump), he became the subject of our test. The
                    output was impressive enough that we decided to release a
                    limited collection of it to the world.
                </p>
                <p>
                    Only 1000 Vitaliks will ever be available. As each new
                    Vitalik is sold, the next one will be made available at an
                    exponentially higher price until the collection is sold out.
                    There are a total of 77 traits, with varying rarity
                    including some ultra-rare Easter Eggs. Ownership of a
                    Vitalik will grant you access to an exclusive community
                    called The Trap House, as well as future launches of our
                    roadmap items based on ownership milestones.
                </p>
                <p>
                    The collection is managed on the Ethereum blockchain,
                    through a decentralized smart contract which controls the
                    buying, selling, and transferring of each Vitalik. This
                    website exists to provide you a set of tools to buy and
                    manage Vitaliks you control.
                </p>
                <v-btn
                    v-if="!this.account"
                    block
                    color="primary"
                    class="text-center metamask-button"
                    @click="requestWallet()"
                >
                    CONNECT TO METAMASK
                </v-btn>
            </v-col>
        </v-row>

        <v-row id="all-vitaliks-section">
            <v-col md="10">
                <div>
                    <h4 class="first-section-title text-center">
                        All Active (USED) Vitaliks
                    </h4>
                    <div v-if="this.isLoading" class="text-center">
                        <v-progress-circular
                            :size="100"
                            :width="10"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                    <div v-if="!this.isLoading">
                        <v-row v-if="latestVitalik">
                            <v-col
                                xs="12"
                                sm="6"
                                md="4"
                                lg="2"
                                xl="2"
                                v-for="i in Number(latestVitalik.id) - 1"
                                :key="i"
                            >
                                <VitalikListing
                                    v-if="latestVitalik"
                                    :isVirgin="false"
                                    :vitalikId="i"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row id="roadmap">
            <v-col>
                <RoadMap />
            </v-col>
        </v-row>

        <ImageViewer />
    </div>
</template>

<style>
p {
    font-family: Poppins, sans-serif;
    font-size: 18px;
}

h4 {
    font-weight: normal;
    color: rgb(4, 17, 29);
    font-size: 22px;
    font-family: "Poppins";
}

#all-vitaliks-section {
    display: flex;
    justify-content: center;
    margin: 60px auto;
}

.latest-vitalik-container {
    padding: 30px;
    display: flex;
    justify-content: center;
    margin: 60px auto;
}

.first-section-title {
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
    margin: 0px 0px 60px 0px !important;
}

.section-title {
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
    margin: 120px 0px 60px 0px !important;
}

.vitalik-title {
    text-transform: uppercase;
    font-family: "Poppins";
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
}

.vitalik-sale-title {
    font-family: "Poppins";
    text-align: center;
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
}

.vitalik-sale-subtitle {
    font-family: "Poppins";
    text-align: center;
    /* color: rgb(24, 104, 183); */
    color: red;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.latest-vitalik-listing {
    margin: auto;
}

.latest-vitalik-title {
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
    /* margin: 20px 0px !important; */
    margin-bottom: 30px;
}

.main-headline {
    text-align: center;
    margin: 0px 0px 50px 0px !important;
    color: rgb(4, 17, 29);
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0px;
}

.metamask-button {
    padding: 30px 0px !important;
    font-size: 1.1rem;
    margin: 50px 0px;
}

.roadmap {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

import VitalikListing from "../components/shared/VitalikListing.vue";
import RoadMap from "../components/RoadMap.vue";
import ImageViewer from "../components/shared/ImageViewer.vue";

export default {
    name: "Home",

    components: {
        VitalikListing,
        RoadMap,
        ImageViewer,
    },

    methods: {
        ...mapActions([
            "requestWallet",
            "purchaseVitalik",
            "purchaseLatestVitalik",
        ]),
    },

    computed: {
        ...mapGetters([
            "isLoading",
            "account",
            "latestVitalik",
            "currentAppStatus",
            "vitaliksWithOwner",
        ]),
    },

    async mounted() {
        await this.$store.dispatch("initSmartContract");
        await this.$store.dispatch("getLatestVitalik");
    },
};
</script>
