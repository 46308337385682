import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Home from "../views/Home.vue";
import VitalikManager from "../views/VitalikManager.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            wallet: false,
            title: "Home",
            noMainNav: false,
        },
    },
    {
        path: "/manage-vitaliks",
        name: "manage-vitaliks",
        component: VitalikManager,
        meta: {
            wallet: true,
            title: "Manage Vitaliks",
            noMainNav: false,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    if (to.meta.wallet && !store.state.web3.account) {
        console.warn("Please connect to your wallet first.");
        next({ name: "home" });
    } else {
        next();
    }
});

export default router;
