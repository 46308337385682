import Vue from "vue";
import Vuex from "vuex";

import imageViewer from "./modules/imageViewer";
import web3Module from "./modules/web3Module";
import homeModule from "./modules/homeModule";
import vitalikManagerModule from "./modules/vitalikManagerModule";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        imageViewer: imageViewer,
        web3: web3Module,
        home: homeModule,
        vitalikManager: vitalikManagerModule,
    },
});
