<template>
    <v-app>
        <MainNavigation v-if="!this.$router.history.current.meta.noMainNav">
        </MainNavigation>

        <v-main style="height: 100%; background-color: #ffffff">
            <router-view />
        </v-main>

        <Footer />
    </v-app>
</template>

<script>
import MainNavigation from "./components/MainNavigation.vue";
import Footer from "./components/Footer.vue";

export default {
    name: "App",

    components: {
        MainNavigation,
        Footer,
    },

    async mounted() {
        await this.$store.dispatch("setTotalVitaliks");
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
</style>
