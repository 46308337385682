<template>
    <div>
        <v-dialog
            persistent
            v-model="this.dialogIsActive"
            transition="dialog-bottom-transition"
            max-width="1200"
        >
            <v-tabs
                icons-and-text
                fixed-tabs
                background-color="indigo"
                dark
                v-model="tab"
            >
                <v-tab key="one">Sell Vitalik</v-tab>
                <v-tab key="two">Transfer Vitalik</v-tab>
                <v-tab key="three">Take Vitalik Off Market</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    key="one"
                    transition="scroll-x-transition"
                    reverse-transition="scroll-x-reverse-transition"
                >
                    <v-card flat>
                        <v-img
                            contain
                            class="mt-5 text-center owned-vitalik-dialog-image"
                            lazy-src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
                            max-width="350"
                            :src="`images/${this.selectedVitalik}-compressed.png`"
                        >
                        </v-img>
                        <v-card-text>
                            <h5 class="justify-center vitalik-name">
                                VITALIK ID {{ this.selectedVitalik }}
                            </h5>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-text-field
                                v-model="vitalikSalePrice"
                                type="number"
                                label="Enter amount to sell for in ETH..."
                                suffix="ETH"
                                class="mt-2"
                            />
                        </v-card-actions>
                        <v-card-actions class="justify-center">
                            <v-btn
                                @click="
                                    sellOwnedVitalik({
                                        id: selectedVitalik,
                                        price: vitalikSalePrice,
                                    })
                                "
                                style="max-width: 100%"
                                class="rock-button"
                            >
                                SELL VITALIK
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="closeDialog()">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                    key="two"
                    transition="scroll-x-transition"
                    reverse-transition="scroll-x-reverse-transition"
                >
                    <v-card flat>
                        <v-img
                            contain
                            class="mt-5 text-center owned-vitalik-dialog-image"
                            lazy-src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
                            max-width="350"
                            :src="`images/${this.selectedVitalik}-compressed.png`"
                        >
                        </v-img>
                        <v-card-text>
                            <h5 class="justify-center vitalik-name">
                                VITALIK ID {{ this.selectedVitalik }}
                            </h5>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-text-field
                                v-model="vitalikGiftAddress"
                                type="text"
                                label="Enter Ethereum address to transfer Vitalik to..."
                                class="mt-2"
                            />
                        </v-card-actions>
                        <v-card-actions class="justify-center">
                            <v-btn
                                @click="
                                    giftOwnedVitalik({
                                        id: selectedVitalik,
                                        receiver: vitalikGiftAddress,
                                    })
                                "
                                style="max-width: 100%"
                                class="rock-button"
                            >
                                TRANSFER VITALIK
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="closeDialog()">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                    key="three"
                    transition="scroll-x-transition"
                    reverse-transition="scroll-x-reverse-transition"
                >
                    <v-card flat>
                        <v-img
                            contain
                            class="mt-5 text-center owned-vitalik-dialog-image"
                            lazy-src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
                            max-width="350"
                            :src="`images/${this.selectedVitalik}-compressed.png`"
                        >
                        </v-img>
                        <v-card-text>
                            <h5 class="justify-center vitalik-name">
                                VITALIK ID {{ this.selectedVitalik }}
                            </h5>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn
                                @click="
                                    takeOwnedVitalikOffMarket(selectedVitalik)
                                "
                                style="max-width: 100%"
                                class="rock-button"
                            >
                                TAKE VITALIK OFF MARKET
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="closeDialog()">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters(["dialogIsActive", "selectedVitalik"]),
    },

    data() {
        return {
            tab: null,
            vitalikSalePrice: "",
            vitalikGiftAddress: "",
        };
    },

    methods: {
        ...mapActions([
            "sellOwnedVitalik",
            "giftOwnedVitalik",
            "takeOwnedVitalikOffMarket",
        ]),

        closeDialog() {
            this.$store.commit("SET_DIALOG", false);
            this.$store.commit("SET_SELECTED_VITALIK", null);
        },

        setTab(tab) {
            this.currentTab = tab;
        },
    },
};
</script>

<style scoped>
.v-input {
    max-width: 50%;
}

.owned-vitalik-dialog-image {
    margin: auto;
}
</style>
