<template>
    <v-toolbar class="main-toolbar" color="white" dark>
        <router-link style="color: white; text-decoration: none" to="/">
            <v-toolbar-title class="toolbar-title">
                Vitalik Rocks
            </v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
                @click="scrollIntoView('roadmap')"
                class="toolbar-btn"
                key="roadmap"
                to="/#roadmap"
                text
            >
                Roadmap
            </v-btn>
            <v-btn
                @click="scrollIntoView('roadmap')"
                class="toolbar-btn"
                key="view-smart-contract"
                href="https://etherscan.io/address/0x2A04A52a963f1BB3A539107D43D23E911545D895"
                target="_blank"
                text
            >
                View Smart Contract
            </v-btn>
            <v-btn
                v-for="item in menu"
                class="toolbar-btn"
                :key="item.key"
                :to="item.link"
                :href="item.link"
                text
            >
                {{ item.title }}
            </v-btn>
        </v-toolbar-items>
        <v-app-bar-nav-icon
            class="main-toolbar-mobile-icon hidden-md-and-up"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <div class="social-links-container">
            <v-btn
                v-for="social in socials"
                :key="social.key"
                :href="social.link"
                target="_blank"
                icon
            >
                <v-icon size="24px" color="rgba(4, 17, 29, 0.75)">
                    {{ social.icon }}
                </v-icon>
            </v-btn>
        </div>
        <!-- <v-menu class="hidden-md-and-up">
            <v-toolbar-side-icon></v-toolbar-side-icon>
            <v-list>
                <v-list-tile v-for="item in menu" :key="item.key">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-menu> -->
    </v-toolbar>
</template>

<script>
export default {
    name: "MainNavigation",
    data() {
        return {
            menu: [
                {
                    key: "manage-vitaliks",
                    link: "/manage-vitaliks",
                    title: "Manage My Vitaliks",
                },
            ],
            socials: [
                {
                    key: "twitter",
                    icon: "mdi-twitter",
                    link: "https://twitter.com/vitalikrocksnft",
                },
                {
                    key: "discord",
                    icon: "mdi-discord",
                    link: "https://discord.gg/MFavjGnJBY",
                },
            ],
        };
    },

    methods: {
        scrollIntoView(id) {
            const element = document.getElementById(id);

            element.scrollIntoView({ behavior: "smooth" });
        },
    },
};
</script>

<style scoped>
.main-toolbar {
    position: fixed;
    width: 100%;
    z-index: 99;
}

.main-toolbar-mobile-icon {
    color: black !important;
}

.toolbar-title {
    color: rgba(4, 17, 29, 0.75) !important;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
}

.toolbar-btn {
    background-color: white !important;
    color: rgba(4, 17, 29, 0.75) !important;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
}

.social-links-container {
    display: flex;
    justify-content: end;
}

.social-link {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: rgb(32, 129, 226);
    border: 1px solid rgb(32, 129, 226);
    border-radius: 10px;
    max-height: 50px;
    padding: 15px 10px;
    text-decoration: none;
}

.social-title {
    text-align: end;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
}
</style>
