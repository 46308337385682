<template>
    <div>
        <h4 class="section-title text-center">Roadmap</h4>

        <v-row class="roadmap-phase" v-for="phase in phases" :key="phase.key">
            <v-col md="2">
                <p class="phase-title">{{ phase.title }}</p>
            </v-col>
            <v-col md="5">
                <p>{{ phase.goal }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Roadmap",
    data() {
        return {
            phases: [
                {
                    key: "phase1",
                    title: "Phase 1 - Dev Magic",
                    goal: "Programmatically build photo-realistic renders of mesh objects placed on a 2D photo.",
                },
                {
                    key: "phase2",
                    title: "Phase 2 - Community Building",
                    goal: "Launch Vitalik.Rocks and build community support and awareness throughout the NFT industry.",
                },
                {
                    key: "phase3",
                    title: "Phase 3 - Rarity Reveal",
                    goal: "After sellout the rarity ranking of each Vitalik will be revealed.",
                },
                {
                    key: "phase4",
                    title: "Phase 4  - Game Development",
                    goal: "At 80% Sold, prepare for v2 [game info coming soon].",
                },
            ],
        };
    },
};
</script>

<style scoped>
.roadmap-phase {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.phase-title {
    color: rgb(24, 104, 183);
}
</style>
