export default {
    state: {
        vitalikToView: null,
        imageViewerVisibility: false,
    },
    mutations: {
        SET_VITALIK_TO_VIEW: (state, vitalikId) =>
            (state.vitalikToView = vitalikId),
        SET_IMAGE_VIEWER_VISIBILITY: (state, visibility) =>
            (state.imageViewerVisibility = visibility),
    },
    actions: {},
    getters: {
        vitalikToView: (state) => state.vitalikToView,
        imageViewerVisibility: (state) => state.imageViewerVisibility,
    },
};
