var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"vitalik-card"},[(parseInt(this.vitalikId) !== this.totalVitaliks + 1)?_c('div',[_c('v-img',{staticClass:"text-center vitalik-image",attrs:{"contain":"","src":("images/" + (this.vitalikId) + "-compressed.png")},on:{"click":function($event){return _vm.viewOriginal()}}}),_c('v-card-title',{staticClass:"justify-center vitalik-name"},[_vm._v(" Vitalik ID "+_vm._s(this.vitalikId)+" ")]),(this.vitalikCurrentlyForSale)?_c('v-card-text',{staticClass:"vitalik-card-text text-center",attrs:{"id":"vitalik-card-price"}},[_vm._v(" "+_vm._s(this.vitalikPrice.toString())+" ETH ")]):_vm._e(),(
                !this.isOwnedVitalik &&
                this.vitalikCurrentlyForSale &&
                this.account
            )?_c('v-card-actions',{staticClass:"vitalik-buy-button justify-center",staticStyle:{"margin-bottom":"10px"}},[_c('v-btn',{attrs:{"color":"primary","block":"","elevation":"4"},on:{"click":function($event){return _vm.purchaseVitalik({
                        id: _vm.vitalikId,
                        price: _vm.vitalikPrice,
                    })}}},[_vm._v(" BUY NOW ")])],1):_vm._e(),(!this.vitalikCurrentlyForSale)?_c('v-card-text',{staticClass:"vitalik-card-text text-center"},[_vm._v(" NOT FOR SALE ")]):_vm._e(),(this.isOwnedVitalik)?_c('v-card-actions',{staticClass:"justify-center",staticStyle:{"margin-bottom":"10px"}},[_c('v-btn',{attrs:{"disabled":!this.account ? true : false,"color":"primary","block":"","elevation":"4"},on:{"click":function($event){return _vm.manageVitalik(_vm.vitalikId)}}},[_vm._v(" MANAGE ")])],1):_vm._e()],1):_vm._e(),(parseInt(this.vitalikId) === this.totalVitaliks + 1)?_c('v-card-text',{staticClass:"vitalik-card-text text-center",staticStyle:{"padding":"200px 0px","color":"#000000de"}},[_vm._v(" SOLD OUT! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }