import Web3 from "web3";

export default {
    state: {
        hasError: {},
        isLoading: false,
        currentAppStatus: null,
        latestVitalik: null,
        vitaliksWithOwner: {},
        totalVitaliks: null,
    },
    mutations: {
        SET_LATEST_VITALIK: (state, latestVitalik) =>
            (state.latestVitalik = latestVitalik),
        SET_CURRENT_APP_STATUS: (state, currentAppStatus) =>
            (state.currentAppStatus = currentAppStatus),
        SET_VITALIKS_WITH_OWNER: (state, vitaliksWithOwner) =>
            (state.vitaliksWithOwner = { ...vitaliksWithOwner }),
        SET_HAS_ERROR: (state, hasError) =>
            (state.hasError = { ...state.hasError, hasError }),
        SET_IS_LOADING: (state, isLoading) => (state.isLoading = isLoading),
        SET_TOTAL_VITALIKS: (state, totalVitaliks) =>
            (state.totalVitaliks = totalVitaliks),
    },
    actions: {
        purchaseVitalik: async ({ rootState }, latestVitalik) => {
            const { id, price } = latestVitalik;
            const { account } = rootState.web3;
            const { buyVitalik } = rootState.web3.smartContract.methods;

            await buyVitalik(id).send({
                from: account,
                gas: 1000000,
                value: Web3.utils.toWei(price),
            });
            window.location.reload();
        },

        getLatestVitalik: async ({ rootState, commit }) => {
            const { latestNewVitalikForSale, getVitalikInfo } =
                rootState.web3.smartContract.methods;
            const latestNewVitalikNumber =
                await latestNewVitalikForSale().call();
            const {
                0: owner,
                1: currentlyForSale,
                2: price,
                3: timesSold,
            } = await getVitalikInfo(latestNewVitalikNumber).call();

            commit("SET_LATEST_VITALIK", {
                id: latestNewVitalikNumber,
                owner,
                currentlyForSale,
                price: Web3.utils.fromWei(price),
                timesSold,
            });
        },

        getVitalikIdInfo: async ({ rootState }, id) => {
            const { getVitalikInfo } = rootState.web3.smartContract.methods;

            const {
                0: owner,
                1: currentlyForSale,
                2: price,
                3: timesSold,
            } = await getVitalikInfo(id).call();

            return {
                owner,
                currentlyForSale,
                price,
                timesSold,
            };
        },

        setTotalVitaliks: async ({ commit }) => {
            const configResponse = await fetch("/config/config.json", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const { MAX_SUPPLY } = await configResponse.json();

            commit("SET_TOTAL_VITALIKS", MAX_SUPPLY);
        },
    },
    getters: {
        hasError: (state) => state.hasError,
        isLoading: (state) => state.isLoading,
        latestVitalik: (state) => state.latestVitalik,
        currentAppStatus: (state) => state.currentAppStatus,
        vitaliksWithOwner: (state) => state.vitaliksWithOwner,
        totalVitaliks: (state) => state.totalVitaliks,
    },
};
