<template>
    <div>
        <v-row class="vitalik-manager-container">
            <v-col>
                <h3 class="main-headline">
                    Vitalik Rocks - Manage My Vitaliks
                </h3>
                <h4 class="latest-vitalik-title text-center">
                    Latest Vitalik For Sale [Never Before Sold]
                </h4>
                <VitalikListing
                    class="latest-vitalik-listing"
                    v-if="this.latestVitalik"
                    :isVirgin="true"
                    :vitalikId="Number(this.latestVitalik.id)"
                />
            </v-col>
        </v-row>
        <div v-if="this.isLoading" class="text-center">
            <v-progress-circular
                :size="100"
                :width="10"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
        <v-row id="owned-vitaliks-section">
            <v-col md="10">
                <h4 class="all-active-vitaliks-title text-center">
                    My Owned Vitaliks
                </h4>
                <p class="text-center">
                    All the vitaliks below have been purchased at least once --
                    owners can list them for sale, if they like.
                </p>
                <div style="margin-top: 60px" v-if="!this.isLoading">
                    <v-row>
                        <v-col
                            xs="12"
                            sm="6"
                            md="4"
                            lg="2"
                            xl="2"
                            v-for="(ownedVitalik, index) in ownedVitaliks"
                            :key="index"
                        >
                            <VitalikListing
                                v-if="ownedVitalik"
                                :vitalikId="Number(ownedVitalik.id)"
                                :isOwnedVitalik="true"
                            />
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <OwnedVitalikDialog />
        <ImageViewer />
    </div>
</template>

<style scoped>
#owned-vitaliks-section {
    display: flex;
    justify-content: center;
    margin: 60px auto;
}

.vitalik-manager-container {
    padding: 30px;
    display: flex;
    justify-content: center;
    margin: 60px auto;
}

.all-active-vitaliks-title {
    color: rgb(4, 17, 29);
    font-size: 24px;
    font-weight: 600;
    margin: 120px 0px 20px 0px !important;
}
</style>

<script>
import { mapGetters } from "vuex";

import VitalikListing from "../components/shared/VitalikListing.vue";
import OwnedVitalikDialog from "../components/OwnedVitalikDialog.vue";
import ImageViewer from "../components/shared/ImageViewer.vue";

export default {
    name: "VitalikManager",

    components: {
        VitalikListing,
        OwnedVitalikDialog,
        ImageViewer,
    },

    computed: {
        ...mapGetters([
            "isLoading",
            "smartContract",
            "latestVitalik",
            "currentAppStatus",
            "account",
            "ownedVitaliks",
        ]),
        // splicedVitaliks() {
        //     const vitaliks = Object.values(this.ownedVitaliks).map((value) => {
        //         return value;
        //     });
        //     const vitaliksPerRow = 5;
        //     const splitVitaliks = [];

        //     while (vitaliks.length > 0)
        //         splitVitaliks.push(vitaliks.splice(0, vitaliksPerRow));

        //     return splitVitaliks;
        // },
    },

    async mounted() {
        await this.$store.dispatch("getOwnedVitaliks");
    },
};
</script>
