<template>
    <v-card class="vitalik-card">
        <div v-if="parseInt(this.vitalikId) !== this.totalVitaliks + 1">
            <v-img
                @click="viewOriginal()"
                contain
                class="text-center vitalik-image"
                :src="`images/${this.vitalikId}-compressed.png`"
            >
            </v-img>
            <v-card-title class="justify-center vitalik-name">
                Vitalik ID {{ this.vitalikId }}
            </v-card-title>
            <v-card-text
                v-if="this.vitalikCurrentlyForSale"
                class="vitalik-card-text text-center"
                id="vitalik-card-price"
            >
                {{ this.vitalikPrice.toString() }} ETH
            </v-card-text>
            <v-card-actions
                v-if="
                    !this.isOwnedVitalik &&
                    this.vitalikCurrentlyForSale &&
                    this.account
                "
                class="vitalik-buy-button justify-center"
                style="margin-bottom: 10px"
            >
                <v-btn
                    @click="
                        purchaseVitalik({
                            id: vitalikId,
                            price: vitalikPrice,
                        })
                    "
                    color="primary"
                    block
                    elevation="4"
                >
                    BUY NOW
                </v-btn>
            </v-card-actions>
            <v-card-text
                class="vitalik-card-text text-center"
                v-if="!this.vitalikCurrentlyForSale"
            >
                NOT FOR SALE
            </v-card-text>
            <v-card-actions
                v-if="this.isOwnedVitalik"
                style="margin-bottom: 10px"
                class="justify-center"
            >
                <v-btn
                    :disabled="!this.account ? true : false"
                    @click="manageVitalik(vitalikId)"
                    color="primary"
                    block
                    elevation="4"
                >
                    MANAGE
                </v-btn>
            </v-card-actions>
        </div>
        <v-card-text
            style="padding: 200px 0px; color: #000000de"
            v-if="parseInt(this.vitalikId) === this.totalVitaliks + 1"
            class="vitalik-card-text text-center"
        >
            SOLD OUT!
        </v-card-text>
    </v-card>
</template>

<style>
.vitalik-card {
    border-radius: 10px;
    width: 350px;
    /* width: 425px; */
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235);
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
}

.vitalik-card:hover {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px !important;
    transition: all 0.1s ease 0s !important;
    transform: scale(1.05);
}

.vitalik-card:hover .vitalik-buy-button {
    display: flex !important;
}

.vitalik-price-col {
    text-align: right;
}

.vitalik-image {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    border-radius: 4px;
}

.vitalik-image:hover {
    margin: auto;
}

.vitalik-name {
    font-family: "Poppins";
    font-weight: 500;
    color: rgb(53, 56, 64);
    font-size: 14px !important;
    display: flex;
}

.vitalik-id {
    color: rgb(53, 56, 64);
    font-weight: 600;
    letter-spacing: 0.1px;
}

.vitalik-buy-button {
    display: none !important;
}

.vitalik-card-text {
    font-family: "Poppins";
    font-weight: 800 !important;
    font-size: 1rem !important;
}

.vitalik-link {
    font-family: "Poppins";
    font-weight: 600;
    color: rgb(53, 56, 64);
    font-size: 14px;
}
</style>

<script>
import Web3 from "web3";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "VitalikListing",

    props: {
        isOwnedVitalik: Boolean,
        isVirgin: Boolean,
        vitalikId: Number,
    },

    data() {
        return {
            reveal: false,
            vitalikCurrentlyForSale: null,
            vitalikOwner: null,
            vitalikPrice: null,
            vitalikTimesSold: null,
        };
    },

    computed: {
        ...mapGetters([
            "smartContract",
            "account",
            "latestVitalik",
            "ownedVitaliks",
            "totalVitaliks",
        ]),
    },

    methods: {
        ...mapActions(["purchaseVitalik", "getVitalikIdInfo"]),

        viewOriginal() {
            this.$store.commit("SET_VITALIK_TO_VIEW", this.vitalikId);
            this.$store.commit("SET_IMAGE_VIEWER_VISIBILITY", true);
        },

        async manageVitalik(selectedVitalik) {
            this.$store.commit("SET_DIALOG", true);
            this.$store.commit("SET_SELECTED_VITALIK", selectedVitalik);
        },
    },

    async mounted() {
        const { owner, currentlyForSale, price, timesSold } =
            await this.getVitalikIdInfo(this.vitalikId);

        this.vitalikOwner = owner;
        this.vitalikPrice = Web3.utils.fromWei(price);
        this.vitalikCurrentlyForSale = currentlyForSale;
        this.vitalikTimesSold = timesSold;
    },
};
</script>
