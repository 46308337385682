<template>
    <v-footer class="footer">
        <v-row class="footer-container">
            <v-col col="6">
                <h3 class="copyright">
                    ©
                    {{ new Date().getFullYear() }} — Vitalik Rocks
                </h3>
            </v-col>
            <v-col col="6">
                <div>
                    <h3 class="social-title">Join the community</h3>
                    <div class="social-links-container">
                        <v-btn
                            v-for="social in socials"
                            :key="social.key"
                            :href="social.link"
                            target="_blank"
                            dark
                            icon
                        >
                            <v-icon size="24px">
                                {{ social.icon }}
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            socials: [
                {
                    key: "twitter",
                    icon: "mdi-twitter",
                    link: "https://twitter.com/vitalikrocksnft",
                },
                {
                    key: "discord",
                    icon: "mdi-discord",
                    link: "https://discord.gg/MFavjGnJBY",
                },
            ],
        };
    },
};
</script>

<style scoped>
.footer {
    justify-content: center;
    background-color: rgb(24, 104, 183) !important;
}

.footer-container {
    max-width: 1785px;
    padding: 50px;
}

.copyright {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
}

.social-links-container {
    display: flex;
    justify-content: end;
}

.social-link {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: rgb(32, 129, 226);
    border: 1px solid rgb(32, 129, 226);
    border-radius: 10px;
    max-height: 50px;
    padding: 15px 10px;
    text-decoration: none;
}

.social-title {
    text-align: end;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
}
</style>
