import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";

import router from "../../router";

export default {
    state: {
        account: null,
        abi: null,
        config: null,
        smartContract: null,
        web3: null,
    },
    mutations: {
        SET_ACCOUNT: (state, account) => (state.account = account),
        SET_ABI: (state, abi) => (state.abi = abi),
        SET_CONFIG: (state, config) => (state.config = config),
        SET_SMART_CONTRACT: (state, smartContract) =>
            (state.smartContract = smartContract),
        SET_WEB3: (state, web3) => (state.web3 = web3),
    },
    actions: {
        initSmartContract: async ({ commit }) => {
            const { ethereum } = window;
            const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

            if (!metamaskIsInstalled) {
                commit("SET_HAS_ERROR", "Install Metamask.");
                commit(
                    "SET_CURRENT_APP_STATUS",
                    "You do not have Metamask installed, please install Metamask in order to use this dapp."
                );
            }

            commit(
                "SET_CURRENT_APP_STATUS",
                "You have Metamask, but haven't yet allowed it to connect to this website. Click the CONNECT TO METAMASK button below."
            );

            const abiResponse = await fetch("/config/abi.json", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const configResponse = await fetch("/config/config.json", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const abiJson = await abiResponse.json();
            const configJson = await configResponse.json();
            const SmartContractObj = new Web3EthContract(
                abiJson,
                configJson.CONTRACT_ADDRESS
            );
            console.log(SmartContractObj);
            SmartContractObj.setProvider(window.web3.currentProvider);
            commit("SET_ABI", abiJson);
            commit("SET_CONFIG", configJson);
            commit("SET_SMART_CONTRACT", SmartContractObj);
        },

        requestWallet: async ({ state, commit, dispatch }) => {
            const { ethereum } = window;
            const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

            if (!metamaskIsInstalled) {
                commit("SET_HAS_ERROR", "Install Metamask.");
            }

            const web3 = new Web3(window.web3.currentProvider);

            try {
                await ethereum.request({
                    method: "eth_requestAccounts",
                });
                const networkId = await ethereum.request({
                    method: "net_version",
                });

                console.log(networkId);

                if (networkId == state.config.NETWORK.ID) {
                    const accounts = await web3.eth.getAccounts();
                    console.log(accounts);
                    const lowercaseCurrentAccount = await ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    console.log(lowercaseCurrentAccount);

                    const currentAccount = accounts.filter(
                        (account) =>
                            account.toLowerCase() === lowercaseCurrentAccount[0]
                    );

                    commit("SET_ACCOUNT", currentAccount[0]);
                    commit("SET_WEB3", web3);

                    ethereum.on(
                        "accountsChanged",
                        (lowercaseChangedAccount) => {
                            const changedAccount = accounts.filter(
                                (account) =>
                                    account.toLowerCase() ===
                                    lowercaseChangedAccount[0]
                            );

                            commit("SET_ACCOUNT", changedAccount[0]);
                            dispatch("getOwnedVitaliks");
                        }
                    );
                    ethereum.on("chainChanged", () => {
                        console.log("chainchanged");
                        window.location.reload();
                    });
                    router.replace({ name: "manage-vitaliks" });
                }
            } catch (err) {
                commit("SET_HAS_ERROR", "Something went wrong.");
            }
        },
    },
    getters: {
        account: (state) => state.account,
        abi: (state) => state.abi,
        config: (state) => state.abi,
        smartContract: (state) => state.smartContract,
        web3: (state) => state.web3,
    },
};
